<template lang="html">
    <div>
        <b-navbar type="light" class="nav-bg" fixed="top">
            <img src="../assets/img/logo.png" class="rounded mx-auto d-block nav-img" >
        </b-navbar>
        <b-container class="content-section" v-show="showOrder">
            <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
            <b-card no-body class="overflow-hidden mt-1" v-for="item in itemList">
                <b-row no-gutters>
                    <b-col cols="4">
                        <b-card-img v-if="item.image" :src="env.audioUrl+item.image" alt="Image" class="rounded-0 card-img" >
                        </b-card-img>
                        <img v-else src="../assets/img/demo.jpg" alt="Image" class="rounded-0 card-img" >
                    </b-col>
                    <b-col cols="8">
                        <b-card-body class="p-0">
                            <!-- <b-card-text> -->
                            <p class="mt-2">{{item.itemName}}</p>
                            <button class="btn btn-bg mt-2" @click="addToCart(item)">ခြင်းထဲထည့်မည်</button>
                            <!-- </b-card-text> -->
                        </b-card-body>
                    </b-col>
                </b-row>
            </b-card>
        </b-container>
    </div>
</template>

<script lang="js">
    import {
        mapMutations,
        mapState,
        mapActions
    } from 'vuex'
    import env from '../enviorments'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'home',
        components: {
            Loading
        },
        data() {
            return {
                env,
                isLoading: false,
                fullPage: true,
                showOrderInfo: false,
                showOrder: true,
                arr: [{
                        "name": "l1",
                        "lat": 16.856821627561715,
                        "long": 96.17285684591533
                    },
                    {
                        "name": "l2",
                        "lat": 16.858558259941333,
                        "long": 96.21070489297084
                    },
                    {
                        "name": "l3",
                        "lat": 16.859000338700103,
                        "long": 96.17568195104394
                    }
                ],
                arrNear: [],
                lat: "",
                long: "",
                dashboard: [],
                itemList: [],
                cartItems: []
            }
        },
        computed: {
            ...mapState({})
        },
        methods: {
             ...mapMutations({
                add: 'increment' // map `this.add()` to `this.$store.commit('increment')`
                }),
            ...mapActions({
                homeAction: 'homeAction',
            }),
            addToCart(itemToAdd) {
                const cart = JSON.parse(window.localStorage.getItem('cart'))
                if(cart){
                    this.cartItems = cart
                }
                const record = this.cartItems.find(p => p.id === itemToAdd.id)
                if (!record) {
                    this.cartItems.push({'id': itemToAdd.id, 'itemName': itemToAdd.itemName, 'qty': 1});
                } else{
                    record.qty++
                }
                window.localStorage.setItem('cart', JSON.stringify(this.cartItems))
                this.$store.commit('increment')
            },
            distance(lat2, lon2, unit) {
                let lat1 = this.lat
                let lon1 = this.long
                if ((lat1 == lat2) && (lon1 == lon2)) {
                    return 0;
                } else {
                    var radlat1 = Math.PI * lat1 / 180;
                    var radlat2 = Math.PI * lat2 / 180;
                    var theta = lon1 - lon2;
                    var radtheta = Math.PI * theta / 180;
                    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(
                        radtheta);
                    if (dist > 1) {
                        dist = 1;
                    }
                    dist = Math.acos(dist);
                    dist = dist * 180 / Math.PI;
                    dist = dist * 60 * 1.1515;
                    if (unit == "K") {
                        dist = dist * 1.609344
                    }
                    if (unit == "N") {
                        dist = dist * 0.8684
                    }

                    return dist;
                }
            },
            locatorButtonPressed() {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        this.lat = position.coords.latitude
                        this.long = position.coords.longitude
                    },
                    error => {
                        console.log(error.message);
                    },
                )
            },
            calcNearBy() {
                let vm = this
                this.arr.forEach(function (key, value) {
                    let lat2 = key.lat
                    let lon2 = key.long
                    let unit = "k"
                    var near = vm.distance(lat2, lon2, unit)
                    vm.arrNear.push({
                        "name": key.name,
                        "distance": near
                    })
                })

                var min = Math.min.apply(null, vm.arrNear.map(function (item) {
                    return item.distance;
                }))
                console.log(min)
                var nearShop = vm.arrNear.map(function (item) {
                    return item.distance;
                }).indexOf(min);
                console.log(vm.arrNear)
                console.log(vm.arrNear[nearShop])
            },
            async homeDetail() {
                this.isLoading = true;
                await this.homeAction().then(res => {
                    if (res.status == "success") {
                        this.itemList = res.data
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
            },
            orderInfoShow(order) {
                this.showOrder = false
                this.showOrderInfo = true
                this.orders = order
            },
            closeOrderInfo() {
                this.showOrder = true
                this.showOrderInfo = false
            },
        },
        async mounted() {
            this.isLoading = true
            this.homeDetail()
        }
    }
</script>

<style scoped lang="scss">
    .card-img {
        width: 100px; 
        height: 100px;
        padding: 4px;
    }
</style>